import React from "react";
import Navbar from "../../components/Navbar";
import LocationAddresses from "../../components/LocationAddresses";
import PagePresentation from "../../components/PagePresentation";
import MassagesSwiper from "../../components/MassagesSwiper";
import HeroBanner from "../../components/HeroBannerImg";
// import SoinsDisplayer from "../../components/SoinsDisplayer";
import data from '../../data/LandingPage/content.json';

const Cares = () => {
 const dataSoins = data[2]
	return (
	<div>
		<Navbar actualPage={"Soins"} />
		<HeroBanner title={"Soins"} />
		<div>
			<PagePresentation presentation={dataSoins.presentation} />
		</div>
		<MassagesSwiper dataList={dataSoins} typeOfServices={"Soins"} />
		<LocationAddresses />
	</div>
);
};

export default Cares;

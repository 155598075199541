import React from "react";
import BilanLargeCard from "./BilanLargeCard";
import BilanSmallCard from "./BilanSmallCard";
import data from "../../data/LandingPage/content.json";
import "./style.scss";

const BilanDisplayer = ({ windowWidth }) => {
	const dataBilan = data[0];
	const dataBilanServices = dataBilan.services;
	// console.log(dataBilan);
	// console.log(dataBilanServices);
	return (
		<section>
			{dataBilanServices.map((bilan) => {
				if (windowWidth >= 951) {
					return <BilanLargeCard bilanInformations={bilan} />;
				} else {
					return <BilanSmallCard bilanInformations={bilan} />;
				}
			})}
		</section>
	);
};

export default BilanDisplayer;

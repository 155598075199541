import React from "react";
// useLocation, useNavigate
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import "./style.css";

const Navbar = ({ actualPage }) => {
 const links = [
  {
  "name": "Soins",
  "link": "/soins"
  },{
  "name": "Massages",
  "link": "/massages"
  },{
  "name": "Bilan",
  "link": "/bilans-ayurvédique"
  }
]
	// const pathname = useLocation().pathname;
	// {pathname.substring(1).charAt(0).toUpperCase() + pathname.slice(2)}
	// const navigate = useNavigate();
	return (
		<div className="navbar">
			{/* <div onClick={() => navigate("/")}>
			<img src={logo} style={{ height: "20px" }} alt="" />
		</div>
		<div onClick={() => navigate("/soins")}>Soins</div>
		<div onClick={() => navigate("/massages")}>Massages</div>
		<div onClick={() => navigate("/bilans-ayurvédique")}>Bilan</div> */}
			<div>
				<Link to="/">
					<img src={logo} style={{ height: "20px" }} alt="logo voyage en ayurveda" />
				</Link>
    {links.map((link) => {
     return(
     <Link className={actualPage === link.name? 'actual-page' : null} to={link.link}>{link.name}</Link>
     )
    })}
				{/* <Link to="/soins">Soins</Link>
				<Link to="/massages">Massages</Link>
				<Link to="/bilans-ayurvédique">Bilan</Link> */}
			</div>
		</div>
	);
};

export default Navbar;

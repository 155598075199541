import React from "react";
import { useNavigate } from "react-router-dom";
import LandDescrImg from "./LandDescrImg";

const Medium = ({ contentData, windowWidth }) => {
	const navigate = useNavigate();
	return (
		<div className="lp-content-description-md">
			<div className="separator"></div>
			<h3>{contentData.title.toUpperCase()}</h3>
			<div
				className={windowWidth >= 704 ? "lp-content-items-md" : "lp-content-items-sm"}
			>
				{windowWidth <= 704 ? (
					<>
						<LandDescrImg contentData={contentData} />
						<div className="lp-content-text">
							<p style={{ paddingRight: "4%", color: "white", margin: "0px 50px" }}>{contentData.description}</p>
						</div>
					</>
				) : contentData.id % 2 === 0 ? (
					<>
						<LandDescrImg contentData={contentData} />
						<div className="lp-content-text">
							<p style={{ paddingRight: "4%" }}>{contentData.description}</p>
						</div>
					</>
				) : (
					<>
						<div className="lp-content-text">
							<p style={{ paddingLeft: "7%" }}>{contentData.description}</p>
						</div>
						<LandDescrImg contentData={contentData} />
					</>
				)}
			</div>
			<h4
				className="lp-button"
				style={{ marginLeft: `${(windowWidth - 150) / 2}px` }}
				onClick={() => navigate(contentData.link)}
			>
				Découvrir
			</h4>
			{windowWidth <= 700 ? (
				<></>
			) : (
				<div
					className="separator"
					style={{ marginLeft: `${(windowWidth - 15) / 2}px` }}
				></div>
			)}
		</div>
	);
};

export default Medium;

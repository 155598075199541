import React from "react";
import Links from "../../components/Links";
import LocationAddresses from "../../components/LocationAddresses";
import HeroBannerVideo from "../../components/HeroBannerVideo";
import LandingDescription from "../../components/LandingDescriptions";
import PersonalInformations from "../../components/PersonalInformations";
import Events from "../../components/Events";
import Reviews from "../../components/Reviews";

const Home = ({ windowWidth }) => {
	return (
	<div>
		<HeroBannerVideo windowWidth={windowWidth} />
		<Links />
		<PersonalInformations />
		<LandingDescription windowWidth={windowWidth} />
		<Events />
		<Reviews />
		<LocationAddresses />
	</div>
);
};

export default Home;

import React from "react";
import HeroVideo from "./HeroVideo";
import HeroMobile from "./HeroMobile";
import HeroBannerTitleLandingPage from "./HeroBannerTitleLandingPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import HeroBannerSVG from "./HeroBannerSVG";
import "./style.css";

const HeroBanner = ({ windowWidth }) => {
	const scrollDown = () => {
		window.scrollTo({
			top: window.innerHeight,
			behavior: "smooth",
		});
	};

	return (
		<>
			<header className="hero-banner">
				{windowWidth > 500 ? <HeroVideo /> : <HeroMobile />}
				<div className="hero-title">
					<HeroBannerTitleLandingPage />
				</div>
				{/* <HeroBannerSVG/> */}
				<div className="scroll-down-arrow">
					<KeyboardArrowDownIcon
						onClick={() => scrollDown()}
						className="scroll-down-icon"
						fontSize="large"
					/>
				</div>
			</header>
		</>
	);
};

export default HeroBanner;

import React from "react";
import dataAdresses from "../../data/Addresses/data.json";
import AddressRow from "./AddressRow";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import "./style.scss";

const LocationAddresses = () => {
	return (
	<div className="addresses">
		<div className="logo-information">
			<NotListedLocationIcon fontSize="large" style={{ position: "relative" }} />
		</div>
		<h3 id="contact-me" style={{ textAlign: "center", color: "white" }}>
			Tous les rendez-vous pris en ligne se déroulent ici{" "}
		</h3>
		{dataAdresses.map((address) => {
			if (address.display === true && address.id === 2) {
				return <AddressRow addressInformation={address} />;
			}
			return null;
		})}
		<h4>Vous pouvez me contacter directement pour prendre rendez-vous à :</h4>
		{dataAdresses.map((address) => {
			if (address.display === true && address.id !== 2) {
				return <AddressRow addressInformation={address} />;
			}
			return null;
		})}
		<div className="mail-tel">
			<div>
				<a href="mailto:michelsarazin@gmail.com">
					<MarkunreadOutlinedIcon fontSize="large" style={{ position: "relative" }} />
				</a>
				<p>michelsarazin@gmail.com</p>
			</div>
			<div>
				<a href="tel:+330620960613">
					<PhoneAndroidOutlinedIcon fontSize="large" style={{ position: "relative" }} />
				</a>
				<p>06 20 96 06 13</p>
			</div>
		</div>

		<br />

		<h3 style={{ color: "white", textAlign: "center" }}>
			Possibilité de pratiquer des cures ayurvédiques dans la région sur demande
		</h3>
	</div>
);
};

export default LocationAddresses;

import React from 'react';
import { Link } from "react-scroll";


const MassageSlide = ({ serviceInformation, serviceType }) => {
 // console.log(serviceInformation.display)
	const time = serviceInformation.time;

	return (
	<>
		<div className="slide-massages">
			<div className="slide-massages-image">
				{serviceType === "Massages" ? (
					<img
						src={require(`../../assets/images/Massages/${serviceInformation.imageTag}`)}
						alt={serviceInformation.imageAlt}
					/>
				) : (
					<img
						src={require(`../../assets/images/Soins/${serviceInformation.imageTag}`)}
						alt={serviceInformation.imageAlt}
					/>
				)}
			</div>
			{/* {serviceInformation.display === true? className="slide-massages-description" : className="slide-massages-description-on-request"} */}
			{serviceInformation.display === true ? (
				<div className="slide-massages-description">
					<h1>{serviceInformation.name}</h1>
					{time > 60 ? <h3>1 h {time - 60}</h3> : <h3>{time} min</h3>}
					{serviceInformation.name === "Massage Abhyanga" ? (
						<h4>
							{serviceInformation.description_one}
							<a
								href={serviceInformation.description_link}
								target="_blank"
								rel="noreferrer"
								style={{ color: "var(--secondary)", textDecoration: "none" }}
							>
								{serviceInformation.description_link_title}
							</a>
							{serviceInformation.description_two}
						</h4>
					) : (
						<h4>{serviceInformation.description}</h4>
					)}
					<div className="description-list">
						<ul>
							<li>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.resalib.fr/praticien/47373-michel-sarazin-praticien-en-ayurveda-aix-en-provence#newrdvmodal"
									className="swiper-reservation-button"
								>
									Réserver
								</a>
							</li>
							<li>
								<span className="swiper-price">{serviceInformation.price} €</span>
							</li>
						</ul>
					</div>
				</div>
			) : (
				<div className="slide-massages-description on-request">
					<h1>{serviceInformation.name}</h1>
					{time > 60 ? <h3>1 h {time - 60}</h3> : <h3>{time} min</h3>}
					<h4>{serviceInformation.description}</h4>
					<p className="on-request-only">Réservation par mail et téléphone uniquement</p>
					<div className="description-list">
						<ul>
							<li>
								<Link
									to="contact-me"
									spy
									smooth
									offset={0}
									duration={800}
									delay={50}
									className="swiper-reservation-button"
								>
									Me contacter
								</Link>
							</li>
							<li>
								<span className="swiper-price">{serviceInformation.price} €</span>
							</li>
						</ul>
					</div>
				</div>
			)}
		</div>
	</>
);
};
// contact-me
export default MassageSlide;
import React from 'react';

const LandDescrImg = ({ contentData }) => {
 // console.log(contentData)
	return (
	<div className="lp-content-item">
		{/* <span class="img-helper"></span> */}
		<img
			className="lp-content-item-image"
			src={require(`../../assets/images/LandingPage/${contentData.imageTag}`)}
			alt={contentData.imageAlt}
		/>
	</div>
);
};

export default LandDescrImg;
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./style.scss";

const BilanCard = ({ bilanInformations }) => {
	return (
	<div className="bilan-card">
		<div className="bilan-picture">
			<img
				src={require(`../../assets/images/Bilan/${bilanInformations.imageTag}`)}
				alt={bilanInformations.imageAlt}
			/>
		</div>
		<div className="bilan-information">
			<h1>{bilanInformations.name}</h1>
			<p>{bilanInformations.description}</p>
			<div className="technical-bilan-informations">
				<ul>
					<li>
						<div style={{ display: "flex" }}>
							<AccessTimeIcon style={{ position: "relative", marginRight: "5px" }} />
							{bilanInformations.time > 60 ? (
								<h3>1 h {bilanInformations.time - 60}</h3>
							) : (
								<h3>{bilanInformations.time} min</h3>
							)}
						</div>
					</li>
					<li>
						<h3>{bilanInformations.price} €</h3>
					</li>
				</ul>
			</div>
			<div className="bilan-reservation-button">
				<a
					href="https://www.resalib.fr/praticien/47373-michel-sarazin-praticien-en-ayurveda-aix-en-provence#newrdvmodal"
					className="swiper-reservation-button"
					target="_blank"
          rel='noreferrer'
				>
					Réserver
				</a>
			</div>
		</div>
	</div>
);
};

export default BilanCard;

export const specificWords = [
	"Vaayu",
	"Shram",
	"har",
	"Jarahar",
	"Prakriti",
	"Pitta",
	"Vata",
	"Kapha",
	"Abhyanga",
 // Ci-dessous les mêmes mots avec une virgule
	"Vaayu,",
	"Shram,",
	"har,",
	"Jarahar,",
	"Prakriti,",
	"Pitta,",
	"Vata,",
	"Kapha,",
	"Abhyanga,",
];
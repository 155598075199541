import React from "react";
import Navbar from "../../components/Navbar";
import HeroBanner from "../../components/HeroBannerImg";
import MassagesSwiper from "../../components/MassagesSwiper";
// dataMassages.services.imageTag
// import MassageGrid from "../../components/MassageCard";
import LocationAddresses from "../../components/LocationAddresses";
import data from "./../../data/LandingPage/content.json"
import PagePresentation from "../../components/PagePresentation";
import "./style.scss";

		

const Massages = () => {
 const dataMassages = data[1]
	return (
	<div>
		<Navbar actualPage={"Massages"} />
		<HeroBanner title={dataMassages.title} />
		<div>
			<PagePresentation presentation={dataMassages.presentation} />
		</div>
		<MassagesSwiper dataList={dataMassages} typeOfServices={"Massages"} />
		<LocationAddresses />
	</div>
);
};

export default Massages;

import React from 'react';
import Logo from "../../assets/images/logo/logo.png";

const HeroBannerTitleLandingPage = () => {
 return (
  <h2 className="" translate='no'>
					V
					<span>
						<img src={Logo} alt="logo voyage en ayurveda" />
					</span>
     yage en Ayurvéda
				</h2>
 );
};

export default HeroBannerTitleLandingPage;
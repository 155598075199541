import React from 'react';
import BannerVideo from "../../assets/videos/banner.mp4";

const HeroBannerVideo = () => {
 // cette configuration ne marche pas sur mobile, j'ai donc commenté ce qui pouvait faire bugguer la vidéo
 // const preload = "metadata";
 return (
  <div>
   {/* ^--  preload={preload} */}
   {/* <source src={BannerVideo} type="video/mp4"/> */}
    {/* ^-- type="video/mp4"  */}
  {/* <video className="background-video" autoPlay loop muted>
    <source src={BannerVideo}></source>
			</video> */}
   <div
   dangerouslySetInnerHTML={{
    __html: `
    <video 
     class="background-video" 
     autoPlay 
     loop 
     muted
    >
    <source src=${BannerVideo} type="video/mp4"/>
    </video>
    `
   }}
   />
  </div>
 );
};

export default HeroBannerVideo;
import React from "react";
import Large from "./Large";
import Medium from "./Medium";
import "./style.css";

const LandDescrRow = ({ contentData, windowWidth }) => {

	return (
	<>
		{windowWidth >= 877 ? (
			<Large contentData={contentData} />
		) : (
			<Medium contentData={contentData} windowWidth={windowWidth} />
		)}
	</>
);
};

export default LandDescrRow;

import React, {useState} from "react";
import personalData from "../../data/PersonalInformations/personalData.json";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./style.css";

const PersonalInformations = () => {
 const [display, setDisplay] = useState(false);

 const displayDescription = () => {
  setDisplay(true)
 }

 const removeDescription = () => {
  setDisplay(false)
 }

	return (
	<section>
		<div className="profile-container">
			<div className="profile-picture">
				<img
					src={require("../../assets/images/Profile/MichelSarazin.jpg")}
					alt="Michel Sarazin thérapeute / masseur ayurvédique dans le sud de la France : Aix-en-provence, Marseille, Avignon"
				/>
			</div>
			<div className="profile-description">
				<div
					className="profile-text"
					style={display ? { height: "auto" } : { overflow: "hidden", height: "145px" }}
				>
					{personalData.map((paragraphe) => {
						return <p>{paragraphe.content}</p>;
					})}
					<p translate="no">
						<span className="profile-signature"> Michel Sarazin </span>
					</p>
				</div>
				<div className="read-more">
					{display ? (
						<KeyboardArrowUpIcon
							onClick={() => removeDescription()}
							fontSize="large"
							style={{ position: "relative", marginTop: "5px", cursor: "pointer" }}
						/>
					) : (
						<KeyboardArrowDownIcon
							onClick={() => displayDescription()}
							fontSize="large"
							style={{ position: "relative", marginTop: "5px", cursor: "pointer" }}
						/>
					)}
				</div>
			</div>
			{/* <div className="transition"></div>
			<div className="transition transform-plus"></div> */}
		</div>
	</section>
);
};

export default PersonalInformations;

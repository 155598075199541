import React from 'react';
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const AddressRow = ({ addressInformation }) => {
	return (
	<div className="address-row">
		<FmdGoodOutlinedIcon
			style={{ position: "relative", color: "var(--secondary)" }}
			fontSize="large"
		/>
		<div className="address-row-informations">
			<h3>{addressInformation.title}</h3>
			{addressInformation.address === "" ? null : (
				<>
					<p>
						{addressInformation.address}, {addressInformation.city}
					</p>
					<a href={addressInformation.url} target="_blank" rel="noreferrer">
						 {addressInformation.title}
					</a>
				</>
			)}
		</div>
	</div>
);
};

export default AddressRow;
import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Links = () => {
	const navigate = useNavigate();

	return (
		<section className="svg-links-section">
			<div className="svg-links">
				<h3 onClick={() => navigate("/bilans-ayurvédique")}>BILANS</h3>
				<h3 onClick={() => navigate("/massages")}>MASSAGES</h3>
				<h3 onClick={() => navigate("/soins")}>SOINS</h3>
				{/* <div>
					<Link to="/bilans-ayurvédique">BILANS</Link>
				</div>
				<div>
					<Link to="/massages">MASSAGES</Link>
				</div>
				<div>
					<Link to="/soins">SOINS</Link>
				</div> */}
			</div>
		</section>
	);
};

export default Links;

import React from "react";
import data from "../../data/EventsData/content.json";
import Grid from "@mui/material/Grid";
import "./style.scss";

const Events = () => {
	return (
		<div className="events-section">
			<h1>Les événements</h1>
			<div className="events-list">
				<Grid container spacing={2}>
					{data.map((event) => {
						return (
							<>
								<Grid item xs={1} md={3}></Grid>
								<Grid item xs={10} md={6}>
									<div className="event-details">
										<h3>{event.name}</h3>
										<h5>{event.month + " " + event.year}</h5>
										{/* <img
											src={require(`../../assets/images/Events/${event.imageTag}`)}
											alt={event.imageAlt}
										/> */}
                    <div
                    dangerouslySetInnerHTML={{
                      __html: `
                      <video
                      controls
                      muted
                      >
                      <source src=${require(`../../assets/images/Events/${event.imageTag}`)} type="video/mp4"/>
                      </video>
                      `
                    }}/>
										<div>
											<a
												className=""
												href={require(`../../assets/images/Events/${event.pdfTag}`)}
												rel="noreferrer"
												target="_blank"
											>
												Découvrir
											</a>
										</div>
									</div>
								</Grid>

								<Grid item xs={1} md={3}></Grid>
							</>
						);
					})}
				</Grid>
			</div>
		</div>
	);
};

export default Events;

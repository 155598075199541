import React from 'react';
import HeroImg from '../../assets/images/LandingPage/heroMassage.jpg';

const HeroMobile = () => {
 return (
  <div className='hero-background-img'>
   <img src={HeroImg} alt="Massages Ayurvédique - Médecine douce" />
  </div>
 );
};

export default HeroMobile;
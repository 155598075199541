import React from "react";
import Navbar from "../../components/Navbar";
import LocationAddresses from "../../components/LocationAddresses";
import PagePresentation from "../../components/PagePresentation";
import data from "./../../data/LandingPage/content.json";
import HeroBanner from "../../components/HeroBannerImg";
import BilanDisplayer from "../../components/BilanDisplayer";
import StarIcon from "@mui/icons-material/Star";
import './style.css';

const CheckUp = ({ windowWidth }) => {
 const dataCheckUp = data[0]
	return (
	<div>
		<Navbar actualPage={"Bilan"} />
		<HeroBanner title={"Bilan Ayurvédique"} />
		<div>
			<PagePresentation presentation={dataCheckUp.presentation} />
		</div>
		<BilanDisplayer windowWidth={windowWidth} />
  <div style={{width: '40px', height: '40px', margin: '0 auto', textAlign: 'center'}}>
   <StarIcon
			style={{ position: "relative", marginBottom: "10px" }}
			fontSize="large"
		/>
  </div>
		
		<h3 className="checkup-more">Retrouvez le plaisir et la joie au quotidien</h3>
		<LocationAddresses />
	</div>
);
};

export default CheckUp;

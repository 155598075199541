import React from "react";
import LandPageData from "../../data/LandingPage/content.json";
import LandDescrRow from "./LandDescrRow";

const LandingDescription = ({ windowWidth }) => {
	return (
	<>
		{LandPageData.map((data) => {
			return <LandDescrRow contentData={data} windowWidth={windowWidth} />;
		})}
	</>
);
};

export default LandingDescription;

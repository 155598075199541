import React from 'react';
// import { useLocation } from "react-router-dom";
import MassagesImg from "../../assets/images/Massages/MassageAbhyanga2.jpg";
import SoinsImg from "../../assets/images/Soins/SoinsShiroabhyanga2.jpg";
// src/assets/images/Bilan/bilanRestitution.jpg
import BilanImg from "../../assets/images/Bilan/bilanRestitution.jpg";
import './style.css';

const HeroBannerImg = ({title}) => {
 // const pathname = useLocation().pathname;
 // const title = pathname.substring(1).charAt(0).toUpperCase() + pathname.slice(2)
 return (
  <section style={{backgroundImage: `linear-gradient(rgba(38, 24, 19, 0.7), rgba(38, 24, 19, 0.8)), url("${title === 'Massages'? MassagesImg : title === 'Soins'? SoinsImg : BilanImg}")`}} className="hero-image">
   <div className="hero-txt">
    <h1>{title}</h1>
   </div>
  </section>
 );
};

export default HeroBannerImg;
import React from 'react';
import StarIcon from "@mui/icons-material/Star";
import './style.css';

const Reviews = () => {
 return (
  <div className='google-reviews'>
   <h1>Témoignages des patients</h1>
   {/* https://g.page/r/CfPsga32RfewECA/review */}
   <div className='reviews-link'>
    <a href="https://g.page/r/CfPsga32RfewECA/review" target="_blank" rel="noreferrer" className='lp-button' style={{padding: '12px 15px'}} > CONSULTER </a>
   </div>
   <br/>
    <StarIcon fontSize="large" style={{ position: "relative" }}/>
    <StarIcon fontSize="large" style={{ position: "relative" }}/>
    <StarIcon fontSize="large" style={{ position: "relative" }}/>
    <StarIcon fontSize="large" style={{ position: "relative" }}/>
    <StarIcon fontSize="large" style={{ position: "relative" }}/>

  </div>
 );
};

export default Reviews;
import React from 'react';
import { specificWords } from '../../data/SpecificWords/data';

const PagePresentation = ({presentation}) => {
 return (
  <div style={{margin: "3% 15%", height: "auto", textAlign: "start", color: 'white'}}>
   <h2 style={{color: 'var(--secondary)'}}>Présentation</h2>
   {presentation.map((paragraphe) => {
     const splitedParagraphe = paragraphe.content.split(" ")
    if(paragraphe.list != null){
     return (
      <>
      <p>
       {splitedParagraphe.map((word) => {
       if( specificWords.includes(word)){
        return <span className='sanskrit-words'> {word} </span>
       }else{
        return word + " "
       }
      })}
      </p>
       {paragraphe.list.map((sentence) => {
        const splitedSentence = sentence.content.split(" ")
        return (
         <p style={{marginLeft: '25px'}}> - 
         {splitedSentence.map((word) => {
       if( specificWords.includes(word)){
        return <span className='sanskrit-words'> {word} </span>
       }else{
        return word + " "
       }
      })}
         </p>
        )
       })}
      </>
    )
    }else{
     return (
     <p>
       {splitedParagraphe.map((word) => {
       if( specificWords.includes(word)){
        return <span className='sanskrit-words'> {word} </span>
       }else{
        return word + " "
       }
      })}
      </p>
    )
    }
   })}
  </div>
 );
};

export default PagePresentation;
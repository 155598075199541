import React from 'react';
import { useNavigate } from "react-router-dom";

const LanDescrText = ({ contentData }) => {
 const navigate = useNavigate();
	return (
	<div className="lp-content-item">
		<h3>{contentData.title.toUpperCase()}</h3>
		<p>{contentData.description}</p>
		<div>
			<h4
				className="lp-button"
    style={{marginLeft: '10%'}}
				// style={contentData.id % 2 === 0 ? { marginLeft: "10%" } : { marginLeft: "65%" }}
				onClick={() => navigate(contentData.link)}
			>
				Découvrir
			</h4>
		</div>
	</div>
);
};

export default LanDescrText;
import React, {useState} from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import MassageSlide from "./MassageSlide";

const MassagesSwiper = ({ dataList, typeOfServices }) => {

 const [slideId, setSlideId] = useState(0)
 // console.log("typeOfServices")
 // console.log(typeOfServices)

	return (
	<>
		<Swiper
			// install Swiper modules
			className="swiper"
			modules={[Navigation, Pagination, Scrollbar, A11y]}
			spaceBetween={50}
			slidesPerView={1}
			navigation
			pagination={{ clickable: true }}
			scrollbar={{ draggable: true }}
			// onSwiper={(swiper) => console.log(swiper)}
			onSlideChange={(slide) => setSlideId(slide.realIndex)}
		>
			{dataList.services.map((service) => {
				if (service.display !== false) {
					return (
						<SwiperSlide>
							<MassageSlide serviceInformation={service} serviceType={typeOfServices} />
						</SwiperSlide>
					);
				} else {
					return null;
				}
			})}
		</Swiper>

		{slideId === 3 && typeOfServices === "Massages" ? (
			<p className="pregnant-massage-information">
				Le massage de la femme enceinte s'accompagne de conseils aux deux parents et
				d'exercices pratiques sur poupon pour l'apprentissage du massage du bébé
				(Shantala)
			</p>
		) : null}
	</>
);
};

export default MassagesSwiper;

import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Cares from "./pages/Cares";
import CheckUp from "./pages/CheckUp";
import Massages from "./pages/Massages";

const Router = ({ windowWidth }) => {
 return (
  <Routes>
   <Route exact path="/" element={<Home windowWidth={windowWidth}/>}/>
   <Route exact path="/soins" element={<Cares />}/>
   <Route exact path="/massages" element={<Massages />}/>
   <Route exact path="/bilans-ayurvédique" element={<CheckUp windowWidth={windowWidth}/>}/>
   <Route path="*" element={<Navigate to="/" />} />
  </Routes>
 );
};

export default Router;
import React from 'react';
import LandDescrImg from "./LandDescrImg";
import LandDescrText from "./LandDescrText";
import "./style.css";

const Large = ({contentData}) => {
 return (
  <div>
  <div className="lp-content-description">
   {contentData.id % 2 === 0 ? (
			<>
				<LandDescrImg contentData={contentData} />
				<LandDescrText contentData={contentData} />
			</>
		) : (
			<>
				<LandDescrText contentData={contentData} />
				<LandDescrImg contentData={contentData} />
			</>
		)}
  </div>
  </div>
  
 );
};

export default Large;
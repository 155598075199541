import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/enso.png";
import links from "../../data/LandingPage/content.json";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LogoFb from "../../assets/images/logo/rs/fb.png"
import LogoInsta from "../../assets/images/logo/rs/Instagram.png";
import "./style.css";

const Footer = () => {
	const navigate = useNavigate();

	return (
	<footer>
		<div className="footer-separator"></div>
		<div className="footer-grid">
			<div className="footer-logo">
				<img className="logo-enso" src={logo} alt="logo voyage en ayurveda" />
			</div>
			<div className="footer-prestations">
				<div className="footer-items-content">
					<h3>Prestations</h3>
					{links.map((link) => {
						return (
	<p onClick={() => navigate(link.link)}>
		<span className="clickable">{link.title}</span>
	</p>
);
					})}
				</div>
			</div>
			<div className="footer-informations">
				<div className="footer-items-content">
					<h3>Contacts</h3>
					<ul>
						<li>
							<a href="mailto:michelsarazin@gmail.com">
								<MarkunreadOutlinedIcon fontSize="large" style={{ position: "relative" }} />
							</a>
						</li>
						<li>
							<p>michelsarazin@gmail.com</p>
						</li>
					</ul>
					<ul>
						<li>
							<a href="tel:+330620960613">
								<PhoneAndroidOutlinedIcon fontSize="large" style={{ position: "relative" }} />
							</a>
						</li>
						<li>
							<p>06 20 96 06 13</p>
						</li>
					</ul>
					<ul className="rs-links">
						<li>
							<a
								href="https://www.facebook.com/Voyage-en-Ayurveda-105093191676358"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={LogoFb}
									className="logo-facebook"
									alt="Accès à la page facebook de Voyage en Ayurveda"
								/>
							</a>
						</li>
						<li>
							<a
								href="https://www.instagram.com/voyage_en_ayurveda/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={LogoInsta}
									alt="Accès à la page Instagram de Voyage en Ayurveda"
									style={{ marginTop: "-5px", width: "50px" }}
								/>
							</a>
						</li>
						<li>
							<a
								href="https://www.resalib.fr/praticien/47373-michel-sarazin-praticien-en-ayurveda-eguilles"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src="https://www.resalib.fr/app/images/app/icon-apple.png"
									alt="Accès à la page Resalib de Michel Sarazin, le thérapeute de Voyage en Ayurveda"
         style={{borderRadius: "30px"}}
								/>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
);
};

export default Footer;
